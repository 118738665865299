"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  main: {
    display: 'flex',
    alignItems: 'center',
    right: '.5em',
    '& > :not(*:last-child)': {
      marginRight: '1em'
    }
  }
};
exports["default"] = _default;