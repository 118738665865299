"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _modal = _interopRequireDefault(require("./modal.container"));

var _Actions = _interopRequireDefault(require("../Card/Actions"));

var _Content = _interopRequireDefault(require("../Card/Content"));

var _Header = _interopRequireDefault(require("../Card/Header"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = Object.assign(_modal["default"], {
  Actions: _Actions["default"],
  Content: _Content["default"],
  Header: _Header["default"]
});

exports["default"] = _default;