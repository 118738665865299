"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _list = _interopRequireDefault(require("./list"));

var _Item = _interopRequireDefault(require("./Item"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = Object.assign(_list["default"], {
  Item: _Item["default"]
});

exports["default"] = _default;