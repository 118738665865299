import graphql from '@k-ramel/driver-graphql'
import form from '@k-ramel/driver-form'
import { login } from '@k-mille/ui-login'
import i18n from '@k-mille/driver-i18n'
import router from './router'
import window from './window'

export default {
  form: form({ path: 'ui.form', getState: state => state.ui.form }),
  graphql: graphql(),
  i18n: i18n(),
  login,
  router,
  window,
}
