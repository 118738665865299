import {
  querySellsObjectives,
  mutationAddObjective,
  mutationReplaceObjective,
  mutationRemoveObjective,
} from './sells.operations'

export const load = (action, store, { graphql }) => {
  graphql.query(`
    ${querySellsObjectives()}
  `)
}

export const set = ({ payload }, store) => {
  store.data.sellsObjectives.set(payload)
}

export const add = async ({ payload }, store, { graphql }) => {
  const { from, to, ...input } = payload
  input.dates = { from, to }
  input.type = 'HR'

  const { data } = await graphql.mutation(mutationAddObjective(), { input })
  store.data.sellsObjectives.add(data.addSellObjective)
}

export const update = ({ payload }, store, { graphql }) => {
  const { from, to, ...input } = payload
  input.dates = { from, to }
  input.type = 'HR'

  graphql.mutation(mutationReplaceObjective(), { input })
}

export const remove = ({ payload }, store, { graphql }) => {
  store.data.sellsObjectives.remove(payload)
  graphql.mutation(mutationRemoveObjective(), { id: payload })
}
