"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  main: {
    flex: 1
  },
  lastItem: {
    height: '1px',
    marginTop: '-1px'
  }
};
exports["default"] = _default;