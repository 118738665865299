"use strict";

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _component = _interopRequireDefault(require("../component"));

var _tabs = _interopRequireDefault(require("./tabs.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _slicedToArray(arr, i) {
  return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
}

function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance");
}

function _iterableToArrayLimit(arr, i) {
  if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
    return;
  }

  var _arr = [];
  var _n = true;
  var _d = false;
  var _e = undefined;

  try {
    for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
      _arr.push(_s.value);

      if (i && _arr.length === i) break;
    }
  } catch (err) {
    _d = true;
    _e = err;
  } finally {
    try {
      if (!_n && _i["return"] != null) _i["return"]();
    } finally {
      if (_d) throw _e;
    }
  }

  return _arr;
}

function _arrayWithHoles(arr) {
  if (Array.isArray(arr)) return arr;
}

var Tabs = function Tabs(_ref) {
  var className = _ref.className,
      classes = _ref.classes,
      children = _ref.children,
      titles = _ref.titles;

  var _useState = (0, _react.useState)([]),
      _useState2 = _slicedToArray(_useState, 2),
      tabs = _useState2[0],
      setTabs = _useState2[1];

  var _useState3 = (0, _react.useState)(null),
      _useState4 = _slicedToArray(_useState3, 2),
      selectedTabIndex = _useState4[0],
      setSelectedTabIndex = _useState4[1];

  (0, _react.useEffect)(function () {
    var childrenAsArray = _react.Children.toArray(children);

    setTabs(childrenAsArray.map(function (child, index) {
      return (0, _react.cloneElement)(child, {
        key: index,
        className: (0, _classnames["default"])(child.props.className, classes.body)
      });
    }));
    if (childrenAsArray.length > 0) setSelectedTabIndex(0);else setSelectedTabIndex(null);
  }, [children]);
  return _react["default"].createElement("div", {
    className: className
  }, _react["default"].createElement("div", {
    className: classes.titles
  }, _react.Children.toArray(titles).map(function (title, index) {
    var onClick = function onClick() {
      return setSelectedTabIndex(index);
    };

    var props = {
      key: index,
      className: (0, _classnames["default"])(classes.title, _defineProperty({}, classes.selected, index === selectedTabIndex)),
      onClick: onClick
    };
    if ((0, _react.isValidElement)(title)) return (0, _react.cloneElement)(title, props);
    return _react["default"].createElement("div", props, title);
  })), tabs.length > 0 && selectedTabIndex !== null && tabs[selectedTabIndex]);
};

Tabs.propTypes = {
  className: _propTypes["default"].string,
  classes: _propTypes["default"].object,
  children: _propTypes["default"].array,
  titles: _propTypes["default"].array
};
Tabs.defaultProps = {
  className: undefined,
  classes: {},
  children: [],
  titles: []
};

var _default = (0, _component["default"])({
  styles: _tabs["default"]
})(Tabs);

exports["default"] = _default;