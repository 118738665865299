"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _container = _interopRequireDefault(require("../container"));

var _modal = _interopRequireDefault(require("./modal"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var mapStore = function mapStore(store, _ref) {
  var id = _ref.id,
      _ref$closeOutside = _ref.closeOutside,
      closeOutside = _ref$closeOutside === void 0 ? true : _ref$closeOutside;
  return {
    isOpen: store.ui.modalId.get() === id,
    onClose: function onClose() {
      if (closeOutside) store.ui.modalId.reset();
    }
  };
};

var _default = (0, _container["default"])({
  mapStore: mapStore
})(_modal["default"]);

exports["default"] = _default;