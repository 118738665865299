"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var getColor = function getColor(theme) {
  return function (focus) {
    return function (_ref) {
      var error = _ref.error,
          warning = _ref.warning,
          success = _ref.success;
      if (error) return theme.error;
      if (warning) return theme.warning;
      if (success) return theme.success;
      if (focus) return theme.cta;
      return theme.grey;
    };
  };
};

var checkboxStyle = function checkboxStyle(theme) {
  return {
    cursor: 'pointer',
    outline: 'none',
    userSelect: 'none',
    textDecoration: 'none',
    alignItems: 'center',
    border: "solid 1px ".concat(theme.cta),
    padding: '0 .5em',
    borderRadius: '4px',
    background: function background(_ref2) {
      var checked = _ref2.checked;
      return checked ? theme.cta : theme.background;
    },
    '& > $label': {
      color: function color(_ref3) {
        var checked = _ref3.checked;
        return checked ? theme.background : theme.cta;
      }
    },
    '& > input': {
      display: 'none'
    }
  };
};

var inputStyle = function inputStyle(theme) {
  return function (overwrite) {
    return function (_ref4) {
      var filled = _ref4.filled,
          props = _objectWithoutProperties(_ref4, ["filled"]);

      if (filled) {
        return _objectSpread({
          backgroundColor: theme.extraLightGrey,
          border: 'none',
          borderBottom: "solid 1px ".concat(getColor(theme)(false)(props)),
          borderRadius: 0
        }, overwrite);
      }

      return _objectSpread({
        border: "solid 1px ".concat(getColor(theme)(false)(props)),
        backgroundColor: props.disabled && theme.extraLightGrey,
        borderRadius: '4px'
      }, overwrite);
    };
  };
};

var _default = function _default(theme) {
  return {
    main: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      '&:focus-within > div': {
        color: getColor(theme)(true)
      }
    },
    label: {
      fontSize: '.8em',
      margin: '.8em 0',
      transition: 'color .3s ease',
      color: getColor(theme)(false),
      fontWeight: 600,
      textTransform: 'capitalize',
      '&:after': {
        content: function content(_ref5) {
          var required = _ref5.required;
          return required ? '"*"' : '';
        },
        color: theme.error
      }
    },
    description: {
      color: getColor(theme)(false),
      margin: '.8em 0',
      fontSize: '.8em'
    },
    input: inputStyle(theme)({
      transition: 'border .3s ease',
      outline: 'none',
      padding: '.5em',
      '&:focus': {
        borderColor: getColor(theme)(true),
        caretColor: getColor(theme)(true),
        '&::placeholder': {
          color: getColor(theme)(true)
        }
      },
      '&::placeholder': {
        color: getColor(theme)(false),
        textTransform: 'capitalize'
      }
    }),
    radio: checkboxStyle(theme),
    checkbox: checkboxStyle(theme)
  };
};

exports["default"] = _default;