"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(theme) {
  return {
    main: {
      position: 'fixed',
      display: 'flex',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 100,
      overflow: 'hidden',
      outline: 0,
      flexDirection: 'column',
      justifyContent: 'center'
    },
    background: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: theme.modalBackground,
      opacity: 0.5
    },
    content: {
      display: 'flex',
      margin: '0 auto',
      zIndex: 101
    },
    card: {
      maxWidth: '95%',
      margin: 'auto'
    }
  };
};

exports["default"] = _default;