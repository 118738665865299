"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _kRamel = require("k-ramel");

var _react2 = require("@k-ramel/react");

var _reactJss = require("react-jss");

var _app = require("./app.reactions");

var _themes = _interopRequireDefault(require("./themes"));

require("normalize.css");

require("./styles/app.css");

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) {
      arr2[i] = arr[i];
    }

    return arr2;
  }
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
} // dynamic import


Promise.resolve().then(function () {
  return _interopRequireWildcard(require('./styles/fonts.css'));
});
Promise.resolve().then(function () {
  return _interopRequireWildcard(require('./styles/icon/style.css'));
});

var _default = function _default(definition, _ref) {
  var _ref$listeners = _ref.listeners,
      listeners = _ref$listeners === void 0 ? [] : _ref$listeners,
      drivers = _ref.drivers;
  var store = (0, _kRamel.createStore)(_objectSpread({}, definition, {
    ui: _objectSpread({}, definition.ui, {
      modalId: _kRamel.types.string(),
      swiping: _kRamel.types.object(),
      theme: _kRamel.types.object({
        defaultData: _themes["default"].light
      })
    })
  }), {
    listeners: [].concat(_toConsumableArray(listeners), [(0, _kRamel.when)('@@krml/EXCEPTION')(function (action) {
      return console.error(action.payload);
    }), (0, _kRamel.when)('@@krf/SET>DATA_PROFILE>PROJECT')(_app.setProjectColors)]),
    drivers: drivers
  });
  return function (screens) {
    var App = function App() {
      return _react["default"].createElement(_reactJss.ThemeProvider, {
        theme: store.ui.theme.get()
      }, screens.map(function (screen) {
        return _react["default"].createElement(screen, {
          key: screen.displayName
        });
      }));
    };

    return (0, _react2.provider)(store)(App);
  };
};

exports["default"] = _default;