/* eslint-env browser */
import React from 'react'
import ReactDOM from 'react-dom'
import { app } from '@k-mille/ui-components'
import { definition, options } from './store'
import screens from './screens'

ReactDOM.render(
  React.createElement(
    app(definition, options)(screens),
  ),
  document.getElementById('root'),
)
