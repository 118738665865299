"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _container = _interopRequireDefault(require("../container"));

var _input = _interopRequireDefault(require("./input"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var mapStore = function mapStore(store, ownProps, _ref) {
  var form = _ref.form;
  var formName = ownProps.formName,
      name = ownProps.name,
      value = ownProps.value,
      type = ownProps.type;
  var currentForm = form(formName);
  var checked = type === 'radio' ? currentForm.get(name) === value : !!currentForm.get(name);
  return {
    value: currentForm.get(name),
    error: currentForm.getErrors(name),
    checked: checked,
    onChange: function onChange(e) {
      var changedValue = e.target.value;
      if (type === 'number') changedValue = +changedValue;
      if (type === 'checkbox') changedValue = e.target.checked;
      if (type === 'radio') changedValue = value;
      currentForm.update(name)(changedValue);
    }
  };
};

var _default = (0, _container["default"])({
  mapStore: mapStore
})(_input["default"]);

exports["default"] = _default;