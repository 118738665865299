"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(theme) {
  return {
    '@global @keyframes pulse': {
      '0%': {
        background: theme.withOpacity(theme.secondary, 0.2)
      },
      '50%': {
        background: theme.withOpacity(theme.primary, 0.2)
      },
      '100%': {
        background: theme.withOpacity(theme.secondary, 0.2)
      }
    },
    main: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'baseline',
      padding: '1em',
      whiteSpace: 'pre-wrap',
      animation: function animation(_ref) {
        var loading = _ref.loading;
        return loading ? 'pulse 1.5s infinite ease-in-out' : 'none';
      }
    }
  };
};

exports["default"] = _default;