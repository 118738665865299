import { when } from 'k-ramel'
import {
  add,
  load,
  set,
  update,
  remove,
} from './sells.reactions'

export default [
  when('@@krml/LISTENERS>ADDED>main')(load),
  when('@@graphql/sellsObjectives>ENDED')(set),
  when('@@ui/ON_ADD_OBJECTIVE')(add),
  when('@@ui/ON_UPDATE_OBJECTIVE')(update),
  when('@@ui/ON_REMOVE_OBJECTIVE')(remove),
]
