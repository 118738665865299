"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _uiComponents = require("@k-mille/ui-components");

var _screen = _interopRequireDefault(require("./screen.listeners"));

var _screen2 = _interopRequireDefault(require("./screen"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var name = 'login';

var mapStore = function mapStore(store, ownProps, _ref) {
  var router = _ref.router;
  return {
    name: router.getParam('applicationName'),
    onSubmit: function onSubmit(e) {
      e.preventDefault();
      store.dispatch('@@SUBMIT>LOGIN');
    }
  };
};

var _default = (0, _uiComponents.container)({
  name: name,
  isScreen: true,
  mapStore: mapStore,
  listeners: _screen["default"]
})(_screen2["default"]);

exports["default"] = _default;