"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _uiComponents = require("@k-mille/ui-components");

var _screen = _interopRequireDefault(require("./screen.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
} // TODO: project name should not be hardcoded


var LoginScreen = function LoginScreen(_ref) {
  var classes = _ref.classes,
      name = _ref.name,
      onSubmit = _ref.onSubmit;
  return _react["default"].createElement("div", {
    className: classes.main
  }, _react["default"].createElement("h1", null, "k-mille"), _react["default"].createElement(_uiComponents.Card, {
    onSubmit: onSubmit
  }, _react["default"].createElement(_uiComponents.Card.Header, null, name), _react["default"].createElement(_uiComponents.Card.Content, null, _react["default"].createElement(_uiComponents.Input, {
    label: "Login",
    name: "login",
    formName: "login",
    required: true
  }), _react["default"].createElement(_uiComponents.Input, {
    label: "Password",
    type: "password",
    name: "password",
    formName: "login",
    required: true
  }), _react["default"].createElement(_uiComponents.Input, {
    label: "Project",
    name: "project",
    formName: "login",
    required: true
  })), _react["default"].createElement(_uiComponents.Card.Actions, null, _react["default"].createElement(_uiComponents.Button, {
    primary: true,
    type: "submit"
  }, "Submit"))), _react["default"].createElement("div", {
    id: "particles-js"
  }));
};

LoginScreen.propTypes = {
  classes: _propTypes["default"].object.isRequired,
  name: _propTypes["default"].string,
  onSubmit: _propTypes["default"].func
};
LoginScreen.defaultProps = {
  name: undefined,
  onSubmit: undefined
};
/* FIXME: try on guillaume computer
const componentDidMount = async () => {
  await import('particles.js')

  const findElementWithRetry = (retry = 10) => {
    if (retry === 0) return

    if (!document.getElementById('particles-js')) {
      setTimeout(() => findElementWithRetry(retry - 1), 100)
      return
    }

    window.particlesJS(
      'particles-js',
      {
        retina_detect: true,
        particles: {
          size: {
            value: 1,
          },
          opacity: {
            value: 0.8,
          },
          number: {
            value: 100,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          line_linked: {
            opacity: 0.5,
          },
        },
      },
    )
  }

  findElementWithRetry()
}
// export default component({ componentDidMount })(StyledLoginScreen)
*/

var _default = (0, _uiComponents.component)({
  styles: _screen["default"]
})(LoginScreen);

exports["default"] = _default;