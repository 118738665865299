import _defineProperty from "/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

/* eslint-disable no-undef */
import { types, when } from 'k-ramel';
import { getLang, getLocale, setLocale, formats } from './utils';
var defaultPath = 'i18n';

var getConfig = function getConfig(config) {
  return _objectSpread({
    path: defaultPath,
    getState: function getState(store) {
      return store[defaultPath];
    },
    defaultLang: getLang(),
    localesUrl: "/".concat(defaultPath)
  }, config);
};

var getReducer = function getReducer(_ref) {
  var path = _ref.path,
      defaultLang = _ref.defaultLang;
  return function () {
    return {
      path: path,
      reducer: {
        lang: types.string({
          defaultData: defaultLang
        }),
        locale: types.object({
          defaultData: getLocale()
        }),
        formats: types.object({
          defaultData: formats
        })
      }
    };
  };
};

var _init = function init(config) {
  return function (action, store) {
    config.getState(store).lang.set(config.defaultLang);
  };
};

export default (function (overwriteConfig) {
  var config = getConfig(overwriteConfig);
  return {
    getReducer: getReducer(config),
    init: function init(store) {
      store.listeners.add([when('@@router/INIT')(_init(config)), when('@@krf/SET>I18N>LANG')(setLocale(config))]);
    },
    getDriver: function getDriver() {
      return {
        getBrowserLang: getLang
      };
    }
  };
});