"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _container = _interopRequireDefault(require("../container"));

var _button = _interopRequireDefault(require("./button"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var mapStore = function mapStore(store, _ref) {
  var id = _ref.id,
      name = _ref.name,
      _onClick = _ref.onClick,
      toRouteCode = _ref.toRouteCode,
      _ref$propagate = _ref.propagate,
      propagate = _ref$propagate === void 0 ? false : _ref$propagate,
      href = _ref.href,
      type = _ref.type;
  if (toRouteCode) return {};
  return {
    onClick: function onClick(e) {
      if (type !== 'submit') store.dispatch({
        type: "@@ui/".concat(href ? 'LINK' : 'BUTTON', ">").concat(name),
        payload: {
          id: id,
          name: name
        }
      });
      if (!propagate) e.stopPropagation();
      if (!href && type !== 'submit') e.preventDefault();
      if (_onClick) _onClick(e);
    }
  };
};

var _default = (0, _container["default"])({
  mapStore: mapStore
})(_button["default"]);

exports["default"] = _default;