const cell = {
  display: 'table-cell',
}

export default {
  main: {
  },
  table: {
    display: 'table',
  },
  row: {
    display: 'table-row',
    '& > *': cell,
    '&:first-child': {
      fontWeight: 'bold',
    },
  },
}
