"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _component = _interopRequireDefault(require("../component"));

var _input = _interopRequireDefault(require("./input.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var Input = function Input(_ref) {
  var className = _ref.className,
      classes = _ref.classes,
      label = _ref.label,
      name = _ref.name,
      formName = _ref.formName,
      type = _ref.type,
      description = _ref.description,
      props = _objectWithoutProperties(_ref, ["className", "classes", "label", "name", "formName", "type", "description"]);

  var id = "".concat(formName, "-").concat(name, "-").concat(label);
  return _react["default"].createElement("label", {
    className: (0, _classnames["default"])(className, classes.main, classes[type]),
    htmlFor: id
  }, label && _react["default"].createElement("div", {
    className: classes.label
  }, label), _react["default"].createElement(type === 'textarea' ? type : 'input', _objectSpread({
    id: id,
    className: classes.input,
    name: name,
    type: type
  }, props)), description && _react["default"].createElement("div", {
    className: classes.description
  }, description));
};

Input.propTypes = {
  className: _propTypes["default"].string,
  classes: _propTypes["default"].object,
  type: _propTypes["default"].string,
  formName: _propTypes["default"].string,
  name: _propTypes["default"].string,
  value: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number, _propTypes["default"].bool, _propTypes["default"].object]),
  label: _propTypes["default"].string,
  placeholder: _propTypes["default"].string,
  description: _propTypes["default"].string,
  required: _propTypes["default"].bool,
  disabled: _propTypes["default"].bool,
  readOnly: _propTypes["default"].bool,
  checked: _propTypes["default"].bool,
  autoFocus: _propTypes["default"].bool,
  onChange: _propTypes["default"].func,
  onClick: _propTypes["default"].func,
  onKeyPress: _propTypes["default"].func
};
Input.defaultProps = {
  className: undefined,
  classes: {},
  type: 'text',
  formName: undefined,
  name: 'unamed',
  value: undefined,
  label: undefined,
  placeholder: undefined,
  description: undefined,
  required: false,
  disabled: false,
  readOnly: false,
  checked: false,
  autoFocus: false,
  onChange: undefined,
  onClick: undefined,
  onKeyPress: undefined
};

var _default = (0, _component["default"])({
  styles: _input["default"]
})(Input);

exports["default"] = _default;