"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}
/* default theme */


var _default = function _default(overwriteTheme) {
  return _objectSpread({
    primary: '#8500ca',
    secondary: '#4668ff',
    cta: '#8500ca',
    lightCta: '#d2c4e5',
    background: '#FFFFFF',
    lightFont: '#FFFFFF',
    darkFont: '#343434',
    extraLightGrey: '#ECECEC',
    lightGrey: '#D8D8D8',
    grey: '#7A7A7A',
    darkGrey: '#343434',
    listBackground: '#FFFFFF',
    listBackgroundDark: '#F9F9F9',
    listBorder: '#CCCCCC',
    listShadow: "\n    inset 1px 0 0 #dadce0,\n    inset -1px 0 0 #dadce0,\n    0 1px 2px 0 rgba(60,64,67,.3),\n    0 1px 3px 1px rgba(60,64,67,.15)\n  ",
    modalBackground: '#000000',
    cardBackground: '#FFFFFF',
    cardShadow: "\n    0px 1px 3px 0px rgba(0,0,0,0.2),\n    0px 1px 1px 0px rgba(0,0,0,0.14),\n    0px 2px 1px -1px rgba(0,0,0,0.12)\n  ",
    success: '#70E083',
    warning: '#FF9E00',
    error: '#EF4D63',
    withOpacity: function withOpacity(color, opacity) {
      return "".concat(color).concat(Math.round(Number(opacity * 255)).toString(16));
    }
  }, overwriteTheme);
};

exports["default"] = _default;