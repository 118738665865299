import React from 'react'
import PropTypes from 'prop-types'
import { wrapSubmit } from 'from-form-submit'
import { component } from '@k-mille/ui-components'
import Objective from './objective'
import styles from './sells.styles'

const HumanResources = ({
  classes,
  messages,
  sellsObjectives,
  onSubmit,
}) => (
  <div className={classes.main}>
    {messages.title}

    <div className={classes.table}>
      <div className={classes.row}>
        <div>Description</div>
        <div>From</div>
        <div>To</div>
        <div>People</div>
        <div>Unit price</div>
        <div>Days</div>
        <div>Total</div>
        <div>&nbsp;</div>
      </div>

      {sellsObjectives.map(id => <Objective key={id} id={id} className={classes.row} />)}

      <form onSubmit={wrapSubmit(onSubmit)} className={classes.row}>
        <div><input name="description" type="text" /></div>
        <div><input name="from" type="date" required /></div>
        <div><input name="to" type="date" /></div>
        <div><input name="people" type="number" required /></div>
        <div><input name="unitPrice" type="number" required /></div>
        <div><input name="days" type="number" required /></div>
        <div />
        <div><button type="submit">Add</button></div>
      </form>
    </div>
  </div>
)

HumanResources.propTypes = {
  messages: PropTypes.object,
  classes: PropTypes.object,
  sellsObjectives: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func,
}

HumanResources.defaultProps = {
  messages: {},
  classes: {},
  sellsObjectives: [],
  onSubmit: undefined,
}

export default component({ styles })(HumanResources)
