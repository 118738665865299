import { container } from '@k-mille/ui-components'
import listeners from './sells.listeners'
import Component from './sells'

const mapStore = store => ({
  sellsObjectives: store.data.sellsObjectives.getKeys(),
  onSubmit: objective => store.dispatch({ type: '@@ui/ON_ADD_OBJECTIVE', payload: objective }),
})

export default container({
  name: 'main',
  intl: 'sells',
  isScreen: true,
  mapStore,
  listeners,
})(Component)
