/* eslint-disable no-undef */
export default (function (store) {
  var _window = window,
      navigator = _window.navigator;
  var lang = store && store.lang.get();

  if (!lang && navigator) {
    var language = navigator.language,
        userLanguage = navigator.userLanguage,
        languages = navigator.languages;
    lang = language || userLanguage || languages && languages.length > 0 && languages[0]; // TODO: resolve lang en-US => en

    lang = lang.substring(0, 2) || lang;
  }

  return lang;
});