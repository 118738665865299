import _defineProperty from "/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var innerOptions = {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  }
};

var set = function set(options) {
  innerOptions = _objectSpread({}, innerOptions, {}, options, {
    headers: _objectSpread({}, innerOptions.headers, {}, options.headers)
  });
};

var get = function get(options) {
  return _objectSpread({}, innerOptions, {}, options, {
    headers: _objectSpread({}, innerOptions.headers, {}, options.headers)
  });
};

export default Object.assign(innerOptions, {
  set: set,
  get: get
});