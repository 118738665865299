"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _reactSwipeable = require("react-swipeable");

var _classnames = _interopRequireDefault(require("classnames"));

var _component = _interopRequireDefault(require("../component"));

var _card = _interopRequireDefault(require("./card.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var config = {
  delta: 10,
  preventDefaultTouchmoveEvent: true,
  trackTouch: true,
  trackMouse: false,
  rotationAngle: 0
};

var buttonProps = function buttonProps(onClick) {
  return {
    role: 'button',
    tabIndex: 0,
    onClick: onClick,
    onKeyDown: onClick
  };
};

var getType = function getType(_ref) {
  var onSubmit = _ref.onSubmit,
      onClick = _ref.onClick;
  if (onClick) return 'button';
  if (onSubmit) return 'form';
  return 'div';
};

var Card = function Card(_ref2) {
  var className = _ref2.className,
      classes = _ref2.classes,
      children = _ref2.children,
      onSubmit = _ref2.onSubmit,
      onClick = _ref2.onClick,
      onSwipedLeft = _ref2.onSwipedLeft,
      onSwipedRight = _ref2.onSwipedRight,
      onSwiping = _ref2.onSwiping,
      onSwiped = _ref2.onSwiped;
  var handlers = (0, _reactSwipeable.useSwipeable)(_objectSpread({
    onSwipedLeft: onSwipedLeft,
    onSwipedRight: onSwipedRight,
    onSwiping: onSwiping,
    onSwiped: onSwiped
  }, config));
  return _react["default"].createElement(getType({
    onSubmit: onSubmit,
    onClick: onClick
  }), _objectSpread({
    className: (0, _classnames["default"])(className, classes.main, _defineProperty({}, classes.animation, !!onClick)),
    onSubmit: onSubmit
  }, onClick && buttonProps(onClick), {}, (onSwipedLeft || onSwipedRight) && handlers), children);
};

Card.propTypes = {
  className: _propTypes["default"].string,
  classes: _propTypes["default"].object.isRequired,
  children: _propTypes["default"].node,
  onSubmit: _propTypes["default"].func,
  onClick: _propTypes["default"].func,
  onSwipedLeft: _propTypes["default"].func,
  onSwipedRight: _propTypes["default"].func,
  onSwiping: _propTypes["default"].func,
  onSwiped: _propTypes["default"].func
};
Card.defaultProps = {
  className: undefined,
  children: undefined,
  onSubmit: undefined,
  onClick: undefined,
  onSwipedLeft: undefined,
  onSwipedRight: undefined,
  onSwiping: undefined,
  onSwiped: undefined
};

var _default = (0, _component["default"])({
  styles: _card["default"]
})(Card);

exports["default"] = _default;