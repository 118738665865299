import { router } from '@k-redux-router/react-k-ramel'

const routes = {
  '/': {
    code: 'main',

    applicationName: 'Sells',
    public: false,

    '/login': {
      code: 'login',
    },
  },
}

export default router({ routes })
