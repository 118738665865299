"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setProjectColors = void 0;
/* eslint-disable import/prefer-default-export */

var setProjectColors = function setProjectColors(action, store) {
  var _store$data$profile$p = store.data.profile.project.get(),
      parameters = _store$data$profile$p.parameters;

  if (!parameters || !parameters.primaryColor || !parameters.secondaryColor) return;
  store.ui.theme.update({
    primary: parameters.primaryColor,
    secondary: parameters.secondaryColor
  });
};

exports.setProjectColors = setProjectColors;