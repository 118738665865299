"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _container = _interopRequireDefault(require("../../container"));

var _item = _interopRequireDefault(require("./item"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var mapStore = function mapStore(store, _ref) {
  var _onSubmit = _ref.onSubmit;
  if (!_onSubmit) return {};
  return {
    onSubmit: function onSubmit(e) {
      e.preventDefault();

      _onSubmit(e);
    }
  };
};

var _default = (0, _container["default"])({
  mapStore: mapStore
})(_item["default"]);

exports["default"] = _default;