"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(theme) {
  return {
    main: {
      display: 'flex',
      position: 'relative',
      backgroundColor: theme.listBackground,
      flexDirection: 'row',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '1em',
      borderBottom: "1px solid ".concat(theme.listBorder),
      userSelect: 'none',
      outline: 'none',
      transition: 'box-shadow .1s',
      cursor: function cursor(_ref) {
        var onClick = _ref.onClick;
        return onClick ? 'pointer' : 'initial';
      },
      '&:hover, &:active, &:focus-within': {
        boxShadow: theme.listShadow,
        zIndex: 1,
        '& > *': {
          visibility: 'visible'
        }
      }
    }
  };
};

exports["default"] = _default;