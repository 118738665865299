import React from 'react'
import PropTypes from 'prop-types'
import { wrapSubmit } from 'from-form-submit'
import { component } from '@k-mille/ui-components'

const Objective = ({
  className,
  messages,
  from,
  to,
  people,
  days,
  unitPrice,
  description,
  onRemove,
  onUpdate,
}) => (
  <form className={className} onSubmit={wrapSubmit(onUpdate)}>
    <div><input name="description" type="text" defaultValue={description} /></div>
    <div><input name="from" type="date" required defaultValue={new Date(from).toISOString().slice(0, 10)} /></div>
    <div><input name="to" type="date" defaultValue={to && new Date(to).toISOString().slice(0, 10)} /></div>
    <div><input name="people" type="number" required defaultValue={people} /></div>
    <div><input name="unitPrice" type="number" required defaultValue={unitPrice} /></div>
    <div><input name="days" type="number" required defaultValue={days} /></div>
    <div>{messages.total}</div>
    <div>
      <button type="button" onClick={onRemove}>{messages.remove}</button>
      <button type="submit">{messages.update}</button>
    </div>
  </form>
)

Objective.propTypes = {
  className: PropTypes.string,
  messages: PropTypes.object,
  from: PropTypes.number,
  to: PropTypes.number,
  people: PropTypes.number,
  days: PropTypes.number,
  unitPrice: PropTypes.number,
  description: PropTypes.string,
  onRemove: PropTypes.func,
  onUpdate: PropTypes.func,
}

Objective.defaultProps = {
  className: undefined,
  messages: {},
  from: undefined,
  to: undefined,
  people: undefined,
  days: undefined,
  unitPrice: undefined,
  description: undefined,
  onRemove: undefined,
  onUpdate: undefined,
}

export default component()(Objective)
