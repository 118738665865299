"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  titles: {
    display: 'flex',
    marginLeft: '1em',
    marginBottom: '-1px'
  },
  title: {
    cursor: 'pointer',
    padding: '1em',
    border: '1px solid grey',
    borderRight: '0px',
    backgroundColor: '#e0e0e0',
    opacity: '0.8',
    '&:first-child': {
      borderTopLeftRadius: '5px'
    },
    '&:last-child': {
      borderTopRightRadius: '5px',
      borderRight: '1px solid grey'
    }
  },
  selected: {
    backgroundColor: 'white',
    opacity: 1,
    borderBottom: '1px solid white'
  },
  body: {
    border: '1px solid grey',
    borderRadius: '5px',
    padding: '1em'
  }
};
exports["default"] = _default;