"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(theme) {
  return {
    main: {
      /* this is specific to login screen */
      position: 'relative',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.lightFont,
      background: "linear-gradient(to right, ".concat(theme.primary, ", ").concat(theme.secondary, ");"),
      '& > *': {
        zIndex: 10
      },
      '& > #particles-js': {
        zIndex: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '98%',
        height: '98%'
      }
    }
  };
};

exports["default"] = _default;