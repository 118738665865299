"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _component = _interopRequireDefault(require("../component"));

var _icon = _interopRequireDefault(require("./icon.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var Icon = function Icon(_ref) {
  var className = _ref.className,
      classes = _ref.classes,
      children = _ref.children;
  return _react["default"].createElement("i", {
    className: (0, _classnames["default"])("icon-".concat(children), className, classes.main)
  });
};

Icon.propTypes = {
  className: _propTypes["default"].string,
  classes: _propTypes["default"].object.isRequired,
  children: _propTypes["default"].node
};
Icon.defaultProps = {
  className: undefined,
  children: undefined
};

var _default = (0, _component["default"])({
  styles: _icon["default"]
})(Icon);

exports["default"] = _default;