"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _default = function _default(theme) {
  return {
    main: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      backgroundColor: theme.cardBackground,
      color: theme.darkFont,
      borderRadius: '4px',
      outline: 'none',
      padding: 0,
      border: "solid 1px ".concat(theme.lightGrey),
      cursor: function cursor(_ref) {
        var onClick = _ref.onClick;
        return onClick && 'pointer';
      },
      transition: function transition(_ref2) {
        var deltaX = _ref2.deltaX;
        return deltaX ? 'none' : 'transform .15s ease-in-out';
      },
      transform: function transform(_ref3) {
        var deltaX = _ref3.deltaX;
        return "translateX(".concat(-deltaX || 0, "px)");
      }
    },
    animation: {
      transition: 'transform .15s ease-in-out, box-shadow .15s ease-in-out',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: "0 7px 6px 0 ".concat(theme.lightGrey)
      },
      '&:active': {
        transform: 'translateY(0)',
        boxShadow: "0 3px 6px 0 ".concat(theme.lightGrey)
      }
    }
  };
};

exports["default"] = _default;