"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _recompose = require("recompose");

var _react2 = require("@k-ramel/react");

var _reactKRamel = require("@k-redux-router/react-k-ramel");

var _core = _interopRequireDefault(require("hoc-react-loader/build/core"));

var _kIntl = _interopRequireDefault(require("k-intl"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

var _default = function _default() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      isScreen = _ref.isScreen,
      name = _ref.name,
      listeners = _ref.listeners,
      form = _ref.form,
      intl = _ref.intl,
      loader = _ref.loader,
      _ref$LoadingIndicator = _ref.LoadingIndicator,
      LoadingIndicator = _ref$LoadingIndicator === void 0 ? function () {
    return _react["default"].createElement('div');
  } : _ref$LoadingIndicator,
      mapStore = _ref.mapStore;

  var hocs = []; // route (hide or not the component)

  if (isScreen) {
    if (_typeof(isScreen) === 'object' && isScreen.relative) hocs.push((0, _reactKRamel.forRoute)(name));else hocs.push(_reactKRamel.forRoute.absolute(name));
  } // listeners


  if (listeners) hocs.push((0, _react2.listen)(listeners, name)); // form to the context (only if not present into props)

  if (form) {
    hocs.push((0, _recompose.withContext)({
      form: _propTypes["default"].string
    }, function (props) {
      return {
        form: props.form || form
      };
    }));
  } // map store to props


  if (mapStore) hocs.push((0, _react2.inject)(mapStore)); // loader (hide/show elements)

  if (loader) {
    var options = {
      LoadingIndicator: LoadingIndicator
    };

    if (typeof loader === 'string') {
      options.print = [loader];
    }

    hocs.push((0, _core["default"])(options));
  } // internationalization


  if (intl) hocs.push((0, _kIntl["default"])(intl, function (store) {
    return store.i18n;
  }));
  return _recompose.compose.apply(void 0, hocs);
};

exports["default"] = _default;