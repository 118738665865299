"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _kRamel = require("k-ramel");

var _screen = require("./screen.reactions");

var _default = [(0, _kRamel.when)('@@SUBMIT>LOGIN')(_screen.login), (0, _kRamel.when)('@@graphql/auth>ENDED')(_screen.saveToken), (0, _kRamel.when)('@@AUTH>FOUND')(_screen.redirect)];
exports["default"] = _default;