"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

var animation = function animation(theme) {
  return {
    transition: 'transform .15s ease-in-out, box-shadow .15s ease-in-out',
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: "0 7px 6px 0 ".concat(theme.withOpacity(theme.cta, 0.1))
    },
    '&:active, &:focus': {
      transform: 'translateY(0)',
      boxShadow: "0 3px 6px 0 ".concat(theme.withOpacity(theme.cta, 0.1))
    }
  };
};

var disabled = function disabled(theme) {
  return {
    backgroundColor: theme.lightGrey,
    color: function color(_ref) {
      var primary = _ref.primary;
      return primary ? theme.lightFont : theme.darkFont;
    },
    pointerEvents: 'none'
  };
};

var _default = function _default(theme) {
  return {
    main: {
      cursor: 'pointer',
      position: 'relative',
      padding: '0 1em',
      fontSize: '1em',
      height: '3em',
      textTransform: 'uppercase',
      fontWeight: 'bolder',
      border: 'none',
      outline: 'none',
      userSelect: 'none',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      borderRadius: function borderRadius(_ref2) {
        var icon = _ref2.icon;
        return icon ? '50%' : '4px';
      },
      '&:disabled': disabled(theme)
    },
    router: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    "default": {
      background: 'transparent',
      color: theme.cta,
      '&:before': {
        content: '""',
        display: 'block',
        opacity: 0,
        position: 'absolute',
        transitionDuration: '.15s',
        transitionTimingFunction: 'cubic-bezier(0.4,0.0,0.2,1)',
        background: 'none',
        borderRadius: function borderRadius(_ref3) {
          var icon = _ref3.icon;
          return icon ? '50%' : '4px';
        },
        transform: 'scale(0)',
        transitionProperty: 'transform, opacity',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      '&:hover:before': {
        opacity: '1',
        transform: 'scale(1)',
        backgroundColor: theme.withOpacity(theme.cta, 0.1)
      },
      '&:active:before': {
        opacity: '1',
        transform: 'scale(1)',
        backgroundColor: theme.withOpacity(theme.cta, 0.2)
      }
    },
    icon: {
      padding: '.5em',
      height: 'initial',
      '& > i': {
        fontSize: '1.8em'
      }
    },
    gradient: {
      background: "-webkit-linear-gradient(".concat(theme.primary, ", ").concat(theme.secondary, ")"),
      '-webkit-background-clip': 'text',
      '-webkit-text-fill-color': 'transparent'
    },
    action: {
      padding: '.1em',
      width: '3em',
      height: '3em',
      '& > i': {
        fontSize: '2.5em'
      },
      backgroundColor: theme.background,
      boxShadow: "0 1px 2px 0 ".concat(theme.withOpacity(theme.cta, 0.3), ", 0 1px 3px 1px ").concat(theme.withOpacity(theme.cta, 0.15)),
      transition: 'transform .15s ease-in-out, box-shadow .15s ease-in-out',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: "0 1px 3px 0 ".concat(theme.withOpacity(theme.cta, 0.3), ", 0 4px 8px 3px ").concat(theme.withOpacity(theme.cta, 0.15))
      },
      '&:active, &:focus': {
        transform: 'translateY(0)',
        boxShadow: "0 1px 2px 0 ".concat(theme.withOpacity(theme.cta, 0.3), ", 0 1px 3px 1px ").concat(theme.withOpacity(theme.cta, 0.15))
      },
      fontSize: '1.3em'
    },
    primary: _objectSpread({
      background: theme.cta,
      color: theme.lightFont,
      textTransform: 'capitalize'
    }, animation(theme)),
    secondary: _objectSpread({
      background: theme.withOpacity(theme.cta, 0.2),
      color: theme.cta,
      textTransform: 'capitalize'
    }, animation(theme)),
    small: {
      padding: '0 .5em',
      height: '2.25em',
      fontSize: '.875em'
    }
  };
};

exports["default"] = _default;