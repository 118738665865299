"use strict";

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireWildcard(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _reactKRamel = require("@k-redux-router/react-k-ramel");

var _component = _interopRequireDefault(require("../component"));

var _Icon = _interopRequireDefault(require("../Icon"));

var _button = _interopRequireDefault(require("./button.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    if (enumerableOnly) symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    });
    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        _defineProperty(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }

  return obj;
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

var Button = function Button(_ref) {
  var _cn;

  var className = _ref.className,
      classes = _ref.classes,
      href = _ref.href,
      type = _ref.type,
      toRouteCode = _ref.toRouteCode,
      disabled = _ref.disabled,
      children = _ref.children,
      action = _ref.action,
      primary = _ref.primary,
      secondary = _ref.secondary,
      gradient = _ref.gradient,
      small = _ref.small,
      icon = _ref.icon,
      otherProps = _objectWithoutProperties(_ref, ["className", "classes", "href", "type", "toRouteCode", "disabled", "children", "action", "primary", "secondary", "gradient", "small", "icon"]);

  var props = _objectSpread({
    className: (0, _classnames["default"])(className, classes.main, (_cn = {}, _defineProperty(_cn, classes["default"], !(primary || secondary || action)), _defineProperty(_cn, classes.action, action), _defineProperty(_cn, classes.primary, primary), _defineProperty(_cn, classes.secondary, secondary), _defineProperty(_cn, classes.gradient, gradient), _defineProperty(_cn, classes.small, small), _defineProperty(_cn, classes.icon, icon), _defineProperty(_cn, classes.disabled, disabled), _defineProperty(_cn, classes.router, !!toRouteCode), _cn)),
    rel: 'noopener noreferrer',
    type: type,
    href: href,
    disabled: disabled,
    tabIndex: disabled ? '-1' : undefined
  }, otherProps);

  var componentType = 'button';
  if (href) componentType = 'a';

  if (toRouteCode) {
    componentType = _reactKRamel.Link;
    props.code = toRouteCode;
    props.href = undefined;
  }

  return _react["default"].createElement(componentType, props, _react["default"].createElement(_react.Fragment, null, icon && _react["default"].createElement(_Icon["default"], null, icon), children));
};

Button.propTypes = {
  className: _propTypes["default"].string,
  classes: _propTypes["default"].object.isRequired,
  name: _propTypes["default"].string,
  type: _propTypes["default"].string,
  disabled: _propTypes["default"].bool,
  href: _propTypes["default"].string,
  toRouteCode: _propTypes["default"].string,
  target: _propTypes["default"].string,
  action: _propTypes["default"].bool,
  primary: _propTypes["default"].bool,
  secondary: _propTypes["default"].bool,
  gradient: _propTypes["default"].bool,
  small: _propTypes["default"].bool,
  icon: _propTypes["default"].string,
  children: _propTypes["default"].node,
  onClick: _propTypes["default"].func
};
Button.defaultProps = {
  className: undefined,
  name: undefined,
  type: undefined,
  disabled: false,
  href: undefined,
  toRouteCode: undefined,
  target: '_blank',
  action: false,
  primary: false,
  secondary: false,
  gradient: false,
  small: false,
  icon: undefined,
  children: undefined,
  onClick: undefined
};

var _default = (0, _component["default"])({
  styles: _button["default"]
})(Button);

exports["default"] = _default;