"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _OutsideClick = require("libreact/lib/OutsideClick");

var _component = _interopRequireDefault(require("../component"));

var _Card = _interopRequireDefault(require("../Card"));

var _modal = _interopRequireDefault(require("./modal.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function Modal(_ref) {
  var className = _ref.className,
      classes = _ref.classes,
      children = _ref.children,
      isOpen = _ref.isOpen,
      onClose = _ref.onClose;
  if (!isOpen) return null;
  return _react["default"].createElement("div", {
    className: classes.main
  }, _react["default"].createElement("div", {
    className: classes.background
  }), _react["default"].createElement(_OutsideClick.OutsideClick, {
    onClick: onClose
  }, _react["default"].createElement("div", {
    className: (0, _classnames["default"])(className, classes.content)
  }, _react["default"].createElement(_Card["default"], {
    className: classes.card
  }, children))));
}

Modal.propTypes = {
  className: _propTypes["default"].string,
  classes: _propTypes["default"].object.isRequired,
  isOpen: _propTypes["default"].bool,
  children: _propTypes["default"].node,
  onClose: _propTypes["default"].func.isRequired
};
Modal.defaultProps = {
  className: undefined,
  isOpen: false,
  children: undefined
};

var _default = (0, _component["default"])({
  styles: _modal["default"]
})(Modal);

exports["default"] = _default;