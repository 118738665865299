"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  main: {
    fontFamily: '"Muli", sans-serif',
    fontSize: '2em',
    padding: '.5em'
  }
};
exports["default"] = _default;