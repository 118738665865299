"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _classnames = _interopRequireDefault(require("classnames"));

var _component = _interopRequireDefault(require("../../component"));

var _item = _interopRequireDefault(require("./item.styles"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var ListItem = function ListItem(_ref) {
  var className = _ref.className,
      classes = _ref.classes,
      index = _ref.index,
      children = _ref.children,
      onClick = _ref.onClick,
      onSubmit = _ref.onSubmit;
  return _react["default"].createElement(onSubmit ? 'form' : 'div', {
    className: (0, _classnames["default"])(className, classes.main),
    onClick: onClick,
    onKeyPress: onClick,
    role: 'menuitem',
    tabIndex: index,
    onSubmit: onSubmit
  }, children);
};

ListItem.propTypes = {
  className: _propTypes["default"].string,
  classes: _propTypes["default"].object,
  children: _propTypes["default"].node,
  index: _propTypes["default"].number,
  onClick: _propTypes["default"].func,
  onSubmit: _propTypes["default"].func
};
ListItem.defaultProps = {
  className: undefined,
  classes: {},
  children: undefined,
  index: 0,
  onClick: undefined,
  onSubmit: undefined
};

var _default = (0, _component["default"])({
  styles: _item["default"]
})(ListItem);

exports["default"] = _default;