const objectiveFields = `
  id
  dates {
    from
    to
  }
  unitPrice
  days
  total
  people
  sold
  description
`

export const querySellsObjectives = () => `
  sellsObjectives {
    ${objectiveFields}
  }
`

export const mutationAddObjective = () => `
mutation AddSellObjective($input: InputSellObjective!) {
  addSellObjective(input: $input) {
    ${objectiveFields}
  }
}
`

export const mutationReplaceObjective = () => `
mutation ReplaceSellObjective($input: InputReplaceSellObjective!) {
  replaceSellObjective(input: $input) {
    id
  }
}
`

export const mutationRemoveObjective = () => `
mutation RemoveSellObjective($id: String!) {
  removeSellObjective(input: $id) 
}
`
