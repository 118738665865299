"use strict";

function _typeof(obj) {
  if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") {
    _typeof = function _typeof(obj) {
      return typeof obj;
    };
  } else {
    _typeof = function _typeof(obj) {
      return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };
  }

  return _typeof(obj);
}

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _recompose = require("recompose");

var _reactJss = _interopRequireWildcard(require("react-jss"));

function _getRequireWildcardCache() {
  if (typeof WeakMap !== "function") return null;
  var cache = new WeakMap();

  _getRequireWildcardCache = function _getRequireWildcardCache() {
    return cache;
  };

  return cache;
}

function _interopRequireWildcard(obj) {
  if (obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache();

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var _default = function _default() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref$pure = _ref.pure,
      pure = _ref$pure === void 0 ? true : _ref$pure,
      styles = _ref.styles,
      _ref$componentDidMoun = _ref.componentDidMount,
      componentDidMount = _ref$componentDidMoun === void 0 ? undefined : _ref$componentDidMoun;

  return function (Component) {
    var hocs = [];
    var lifecycleOptions = {}; // set a displayName manually since `memo` doesn't set any
    // https://github.com/facebook/react/issues/14319

    var WrappedComponent = Component;

    if (pure) {
      WrappedComponent = (0, _react.memo)(Component);
      WrappedComponent.displayName = "memo(".concat(Component.displayName || Component.name, ")");
    }

    if (componentDidMount) {
      lifecycleOptions.componentDidMount = componentDidMount;
    }

    if (Object.keys(lifecycleOptions).length > 0) hocs.push((0, _recompose.lifecycle)(lifecycleOptions));

    if (styles) {
      hocs.push(_reactJss.withTheme);
      hocs.push((0, _reactJss["default"])(styles));
    }

    return _recompose.compose.apply(void 0, hocs)(WrappedComponent);
  };
};

exports["default"] = _default;