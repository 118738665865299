export default {
  number: {
    EUR: {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    },
    percent: {
      style: 'percent',
      maximumFractionDigits: 2
    }
  },
  date: {
    shortMonth: {
      month: '2-digit',
      year: 'numeric'
    },
    short: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    },
    shortWithTime: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZone: 'UTC'
    }
  }
};