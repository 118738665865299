import { container } from '@k-mille/ui-components'
import Component from './objective'

const mapStore = (store, { id }) => {
  const { dates, ...objective } = store.data.sellsObjectives.get(id)

  return {
    ...objective,
    ...dates,
    total: objective.total || objective.days * objective.unitPrice,
    onUpdate: data => store.dispatch({ type: '@@ui/ON_UPDATE_OBJECTIVE', payload: { ...data, id } }),
    onRemove: () => store.dispatch({ type: '@@ui/ON_REMOVE_OBJECTIVE', payload: id }),
  }
}

export default container({
  mapStore,
  intl: 'sells.objective',
})(Component)
