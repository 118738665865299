"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _item = _interopRequireDefault(require("./item.container"));

var _Actions = _interopRequireDefault(require("./Actions"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var _default = Object.assign(_item["default"], {
  Actions: _Actions["default"]
});

exports["default"] = _default;