"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.redirect = exports.saveToken = exports.login = void 0;

var login = function login(action, store, _ref) {
  var form = _ref.form,
      graphql = _ref.graphql;
  var currentForm = form('login');
  graphql.query("{\n    auth: token(email: \"".concat(currentForm.get('login'), "\", password: \"").concat(currentForm.get('password'), "\", project: \"").concat(currentForm.get('project'), "\") {\n      token\n      email\n      name\n      firstName\n      roles\n      projects {\n        code\n        name\n        parameters {\n          primaryColor\n          secondaryColor\n        }\n      }\n    }\n  }"));
};

exports.login = login;

var saveToken = function saveToken(_ref2, store) {
  var payload = _ref2.payload;
  store.dispatch({
    type: '@@AUTH>FOUND',
    payload: payload
  });
};

exports.saveToken = saveToken;

var redirect = function redirect(action, store, _ref3) {
  var router = _ref3.router;
  router.push('main');
};

exports.redirect = redirect;